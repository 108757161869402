import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { nextEvents } from "./service";
import BCTable from "../../components/BCTable";
import { format, parseISO } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";

export default function AgentsViewEvents() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [currentPage, setPage] = useState(1);
  const [loading] = useState(false);
  const [listEvents, setListEvents] = useState([]);
  const dataLang = i18n.language === "pt" ? ptBR : enUS;

  const handleNextEvent = async () => {
    try {
      const response = await nextEvents(id);
      if (response.result) {
        setListEvents(response.result);
      }
    } catch (error) {
      console.log("erro load next event");
    }
  };

  useEffect(() => {
    handleNextEvent();
  }, []);

  const actionsColumn = [
    {
      key: 1,
      title: t("Title"),
      dataIndex: "title",
      render: (text, data) => {
        return <p style={{ width: "100%", textAlign: "start" }}>{text}</p>;
      },
    },
    {
      key: 2,
      title: t("description"),
      dataIndex: "description",
      render: (text, data) => {
        return <p style={{ width: "100%", textAlign: "start" }}>{text}</p>;
      },
    },
    {
      key: 3,
      title: t("Date"),
      dataIndex: "request_date",
      render: (text, data) => {
        return (
          <p style={{ width: "100%", textAlign: "start" }}>
            {format(parseISO(text), "MMM dd', 'yyyy", {
        locale: dataLang,
      })}
          </p>
        );
      },
    },
  ];

  return (
    <div>
      <h1>{`${t("AgentField")} : ${t("FarmerTitle")}`}</h1>
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={12} sm={12} md={21}></Col>
      </Row>
      <BCTable
        showPlaceholderSearch={false}
        data={listEvents}
        total={listEvents.length}
        pageSize={10}
        currentPage={currentPage}
        loading={loading}
        columns={actionsColumn}
        showCreateButton={false}
        setCurrentPage={setPage}
        setPageSize={() => {}}
      />
    </div>
  );
}
