import React, { createContext, useContext, useEffect, useState } from "react";
import i18n from "../i18n";
import apiClient from "../apis/axiosInterceptorEntitlement";
import { AuthContext } from "./auth";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    changeLanguageStorage();
  }, [user]);

  const changeLanguage = async (newLanguage) => {
    const storageUser = localStorage.getItem("userLogged");
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    let language = newLanguage == "pt" ? "PT" : "EN";
    changeLanguageStorage(newLanguage);
    try {
      if (!storageUser) return;
      await apiClient.patch(`v2/user/set-language`, {
        language,
      });
    } catch (error) {}
  };

  const changeLanguageStorage = async (language) => {
    try {
      const async_lang = "@sabia:language";
      const languageStorage = localStorage.getItem(async_lang);
      if (language) {
        localStorage.setItem(async_lang, language);
        setLanguage(language);
        return;
      }

      setLanguage(languageStorage);
    } catch (error) {
      console.warn("error change language", error);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
