import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      DateNotAvailable: "Date not available",
      Credit: "Credit",
      Deduct: "Deduct",
      Restrict_farmer_carbon_credits: "Restrict Farmer Carbon Credits",
      Manage_credit: "Manage credit",
      Balance: "Balance",
      Emitted: "Emitted",
      Carbon_credit: "Carbon Credit",
      Exchanged: "Exchanged",
      Historic: "Historic",
      Category: "Category",
      Group_field_visit: "Group Field Visit",
      Individual_field_visit: "Individual Field Visit",
      Attended_training: "Attended Training",
      New: "New",
      Assistance: "Assistance",
      No_overlap_with_indigenous_lands:
        "No overlap with indigenous and quilombolas lands",
      No_deforestation_in_the_last_5_years:
        "No deforestation in the last 5 years",
      No_slavery: "No slavery",
      No_overlap_with_preservation_areas: "No overlap with preservation areas",
      No_child_labor: "No child labor",
      Status_of_eligibility_criteria: "Status of Eligibility Criteria",
      Additional_information: "Additional Information",
      Main_field_agent: "Main Field Agent",
      Size: "Size",
      Code: "Code",
      Type_area: "Type of area",
      Type_cultivation: "Type Cultivation",
      label_agent: "Responsible Field Agent",
      label_type_cultivation: "Cultivation type",
      Name_area: "Identifier",
      Property_areas: "Farm Areas",
      ENROLLED: "Enrolled",
      see_on_map: "See on Map",
      Implemented_farmers: "Implemented Farmers",
      Enrolled_farmers: "Enrolled Farmers",
      OTHER: "Other",
      Restauration_area: "Restoration Area",
      Agroforestry_area: "Agroforestry Area",
      ER_implemented: "ER Implemented",
      ER_enrolled: "ER Enrolled",
      AFS__implemented: "AFS Implemented",
      AFS_enrolled: "AFS Enrolled",
      Farmers_enrolled_and_deployed_regional_overview:
        "Farmers Enrolled vs Farmers Deployed - Regional Overview",
      ha_implemented: "ha implemented",
      Total_area_of_registered_farms: "Total Area of Registered Farms",
      Participating: "Participating",
      See_it_all: "See It All",
      Monitoring_of_enrollment_steps: "Monitoring of Enrollment Steps",

      title_information_personal: "PERSONAL INFORMATION",
      label_name: "Name",
      label_email: "Email",
      label_phone: "Mobile Phone",
      label_birth_date: "Date of Birth",
      label_cpf: "CPF",
      label_marital_status: "Marital Status",
      label_level_of_education: "Level of Education",
      label_number_of_working_age_adults: "Number of Working Age Adults",
      label_number_of_children: "Number of Children",

      title_information_property: "PROPERTY INFORMATION",
      label_name_farm: "Name",
      label_total_area: "Total Property Area",
      label_area_to_be_enrolled_afs: "Area to be Enrolled for Agroforestry",
      label_area_to_be_enrolled_er:
        "Area to be Enrolled for Ecological Restoration",
      label_organization_name: "Organization",
      label_city: "City",
      label_state: "State",
      label_main_field_agent: "Main Field Agent",
      label_has_beneficiary_registration: "BR - Beneficiary Registration",
      label_has_land_title: "Land Title",
      label_has_possession: "Possession",
      label_multiple_owners: "Multiple Owners",

      title_information_production: "PRODUCTION INFORMATION",
      label_primary_farm_activity: "Primary Farm Activity",
      label_secondary_farm_activity: "Secondary Farm Activity",
      label_agriculture_area: "Agriculture Area",
      label_forest_area: "Forest Area",
      label_pasture_area: "Pasture Area",
      label_implementing_agroforestry: "Implementing Agroforestry",
      label_cacao_plantation: "Cocoa Plantation",

      label_step_1: "Step 1",
      label_step_1_subtitle: "Request Enrollment",
      label_step_2: "Step 2",
      label_step_2_subtitle: "Program Approval",
      label_step_3: "Step 3",
      label_step_3_subtitle: "Schedule a Field Agent Visit",
      label_step_4: "Step 4",
      label_step_4_subtitle: "Program Areas: Minimum of 1ha Approved",
      label_step_5: "Step 5",
      label_step_5_subtitle: "Sign Contract",
      label_step_6: "Step 6",
      label_step_6_subtitle: "Enrolled in the Programme",

      Overview_of_program_areas: "Overview of Program Areas",
      rate_of_top_10_field_agents:
        "Enrolled vs Implemented - Rate of Top 10 Field Agents",
      Enrolled_vs_Deployed: "Enrolled vs Deployed",
      No_Records: "No Records",
      User_Profile: "User Profile",
      Actions_Plan: "Actions Plan",
      Next_Appointments: "Next Appointments",
      Edit_User: "Edit User",
      VALUE: "Value",
      CREDIT_ADDED: "Credit Added",
      CREDIT_DEDECTED: "Credit Deducted",
      PACKAGE_PURCHASE: "Package Purchase",
      PACKAGE_UPDATE: "Package Update",
      CREATION_OF_VENDOR: "Creation of Vendor",
      MANUAL_CREDITS: "Manual Credits",
      PURCHASE_FROM_INDEPENDENT_SERVICES: "Purchase from Independent Services",
      Localization: "Localization",
      Property: "Property",
      Rejected_Description:
        "If your choice is to reject, please provide a description.",
      Status_Is_Required: "Status is required",
      Supplier: "Supplier",
      Reject: "Reject",
      Accepted: "Accepted",
      Offers: "Offers",
      Destinations: "Destinations",
      Total_Sent: "Total Sent:",
      UserId_Is_Required: "User ID is required",
      Edit_Employee_Data: "Edit Employee Data",
      Comments: "Comments",
      Ratings: "Ratings",
      Save_Updates: "Save Updates",
      SalesmanId_Is_Required: "Salesman ID is required",
      Edit_suppliers: "Edit Supplier",
      Text_important_new_suppliers:
        "Important! When adding a new supplier, they may include new services",
      Text_edit_suppliers:
        "You can change the responsible person for this supplier.",
      EVENT_GROUP: "Event Group",
      Salesman_Is_Required: "Salesman is required",
      Salesmans: "Salesmen",
      has_Full_Control: "Has Full Control",
      Employee_phone: "Employee Phone",
      Employee_name: "Employee Name",
      Employee_email: "Employee Email",
      Invite_a_new_salesman: "Invite a New Salesman",
      Text_new_salesman: "Please fill out the form below to add a new salesman",
      In_Revison: "In Revision",
      ACCEPTED: "Accepted",
      Services_Under_Review: "Services Under Review",
      Service: "Service",
      Cost: "Cost",
      Save_service: "Save Service",
      Text_new_service:
        "Please fill out the form below to define a new service.",
      Information_About_The_Service: "Information About The Service",
      Cost_is_Required: "Cost is required",
      Add_new_Service: "Add New Service",
      Services: "Services",
      To_Manage: "To Manage",
      Add_New_Salesman: "Add New Salesman",
      Edit_Supplier: "Edit Supplier",
      Supplier_Registration: "Supplier Registration",
      Rating: "Rating",
      Available_Services: "Available Services",
      cpf_cnpj: "Enter the CPF or CNPJ",
      Responsible_phone: "Responsible Phone",
      Responsible_email: "Responsible Email",
      Responsible_name: "Responsible Name",
      Text_new_suppliers:
        "Please fill out the form below to invite a new supplier",
      Invite_a_new_suppliers: "Invite a New Supplier",
      Responsible_phone_Is_Required: "Responsible Phone is required",
      Responsible_email_Is_Required: "Responsible Email is required",
      Responsible_name_is_Required: "Responsible Name is required",
      Add_new_Suppliers: "Add New Supplier",
      RESPONSIBLE: "Responsible",
      Select_all: "Select All",
      Origin_is_required: "Origin is required",
      Select_type_notification: "Select the type of notification",
      EVENT: "Event",
      SCHEDULE_REQUEST: "Schedule Request",
      CALL: "Call",
      CHAT: "Chat",
      OTHERS: "Others",
      Write_Your_Notification: "Write Your Notification",
      Send_by_sms: "Send by SMS",
      Send_by_app: "Send by App",
      GENERATING: "Generating",
      SENT: "Sent",
      GENERATED: "Generated",
      GeneratedIn: "Generated In",
      Realized_Quantum: "Quantum Realized",
      LastResponse: "Last Response",
      Type_report_is_required: "Type of report is required",
      To_generate: "Generate",
      New_report: "New Report",
      Type_Report: "Type of Report",
      INCOMPLETE_EXTRA_INFORMATION_CRITERIA:
        "Incomplete Extra Information Criteria",
      SIGNATURE_OF_CONTRACT: "Signature of Contract",
      LAST_30_DAYS: "Last 30 Days",
      LAST_6_MONTHS: "Last 6 Months",
      LAST_YEAR: "Last Year",
      Reports: "Reports",
      Creating: "Creating",
      Start: "Start",
      Message_is_Required: "Message is required",
      Title_is_Required: "Title is required",
      New_chat: "New Chat",
      End_Chat: "End Chat",
      Uploading: "Uploading",
      Write_your_message_here: "Write your message here",
      Just_mine: "Just Mine",
      It_will_only_be_read_when_all_of_the_batch_are_read:
        "It will only be read when all of the batch are read",
      NotRead: "Not Read",
      Read: "Read",
      Sent_by_the_system: "Sent by the system",
      Total_Sent: "Total Sent",
      Author: "Author",
      Message: "Message",
      New_Message: "New Message",
      Notifications: "Notifications",
      Program_Area_title_page: "Program Area Map",
      Subscribers_to_the_Event: "Subscribers to the Event",
      City: "City",
      State: "State",
      Event_Data: "Event Data",
      Close: "Close",
      Participated: "Participated",
      No: "No",
      Responsible: "Responsible",
      Choose_an_event: "Choose an event",
      Check_presence_of_Farmers: "Check presence of Farmers",
      Verify: "Verify participation in events",
      Select_Cities: "Select Cities",
      Localization_is_required: "Localization is required",
      Hour_is_Required: "Hour is required",
      Period_is_Required: "Period is required",
      Type_event_id_Is_Required: "Type event ID is required",
      Description_min_3: "Description must contain at least 3 characters",
      Description_is_Required: "Description is required",
      Date_is_required: "Date is required",
      Address_min_3: "Address must contain at least 3 characters",
      Address_is_Required: "Address is required",
      State_is_Required: "State is required",
      City_is_Required: "City is required",
      Period_or_hour_is_Required: "Hour or period must be filled",
      UploadImage: "Upload Image",
      RemoveImage: "Remove Image",
      Hour: "Hour",
      Period: "Period",
      MORNING: "Morning",
      AFTERNOON: "Afternoon",
      Address: "Address",
      Cities: "Cities",
      States: "States",
      INPERSON: "In-person",
      Description: "Description",
      Title: "Title",
      Type_event: "Type of Event",
      Verify_Event: "Verify Event",
      Add_event: "Add Event",
      Day_Events: "Day Events",
      TRAINING: "Training",
      WORKSHOP: "Workshop",
      FarmerResponse: "Farmer Response",
      View_Item: "View Item",
      Approved_Per_Agent: "Approved Per Agent",
      Rejected__Per_Agent: "Rejected By Agent",
      REJECTED: "Rejected",
      RESOLVED: "Resolved",
      REVIEW: "Review",
      Completed_checklist: "Completed Checklist",
      Created_in: "Created in",
      All: "All",
      Total_Itens: "Total Items",
      Overview_Action_Plans: "Overview of Action Plans",
      Pending_Approval: "Pending Approval",
      ActionsPlans: "Action Plans",
      AgentField: "Field Agent",
      FarmerTitle: "Farmers",
      FarmerTitle: "Events",
      Required: "Required",
      Not_Required: "Not required",
      View_Document: "View Document",
      Not_Response: "Not responded",
      Validate: "Validate",
      Total_Questions: "Total Questions",
      Agent: "Agent",
      agent: "Agent",
      PerformedIn: "Performed in",
      PENDING: "Pending",
      ANSWERED: "Answered",
      EXPIRED: "Expired",
      COMPLETED: "Completed",
      NEW: "NEW",
      APPROVED: "Approved",
      SIGNED: "Signed",
      Date: "Date",
      Finalized: "Finalized",
      In_Progress: "In Progress",
      Performed: "Performed",
      Overview_Checklist: "Overview of Checklists",
      Graphic_4: "Total Enrolled vs Implemented Areas by Ha",
      Graphic_3: "Enrolled vs Implemented Areas",
      Implemented: "Implemented",
      Enrolled: "Enrolled",
      Graphic_2: "Implemented Areas % by Number of Visits",
      Area_implemented: "Area Implemented",
      Number_visits: "Number of Visits",
      Graphic_1: "Farmers vs Field Agents - Region",
      Agents_per_regions: "Agents per Region",
      Registered_farms: "Registered Farms",
      Active: "Active",
      List_available_field_agents: "List of Available Field Agents",
      Reset_field_agent: "Reset Field Agent",
      Identifier: "Identifier",
      Main_Field_Agent_is_Required: "Main Field Agent is Required",
      At_least_one_secondary_field_agent_is_mandatory:
        "At least one secondary field agent is mandatory",
      Main_Field_Agent: "Main Field Agent",
      Secondary_Field_Agents: "Secondary Field Agents",
      ENROLLED_TO_THE_PROGRAM: "Enrolled in the program",
      FLAGGED_FOR_REVIEW: "Flagged for review",
      APPLICATION_DENIED: "Application denied",
      IN_PROGRESS: "In progress",
      Uninformed: "Uninformed",
      Area: "Area",
      Farm_details: "Farm Details",
      Data: "Data",
      List: "List",
      Location: "Location",
      Farms: "Farms",
      Farm: "Farm",
      Farmer: "Farmer",
      farmer: "Farmer",
      admin: "Administrator",
      Define_field_agents: "Define Field Agents",
      Choose_regions: "Choose regions",
      Regions_is_required: "At least one region is required",
      Invite_a_new_admin: "Invite a new administrator",
      Invite_a_new_agent: "Invite a new field agent",
      Choose_permissions: "Choose permissions",
      Permissions_is_Required: "At least one permission is required",
      OrganizationId_Is_Required: "Organization is required",
      Organizations: "Organizations",
      Text_new_admin:
        "Please fill out the form below to invite a new administrator",
      Text_new_agent:
        "Please fill out the form below to invite a new field agent",
      Creation: "Creation",
      Add_new_user: "Add new user",
      Resend_first_access_code: "Resend first access code",
      Added_in: "Added in",
      Never_accessed: "Never accessed",
      Last_access_date: "Last access date",
      Regions: "Regions",
      Search: "Search",
      Actions: "Actions",
      View: "View",
      Delete: "Delete",
      Edit: "Edit",
      Gotoback: "Go back",
      Overview: "Overview",
      Edit_Allocation: "Edit Allocation",
      List_of_Available_Agents: "List of Available Agents",
      History_of_Visits_and_Events: "History of Visits and Events",
      History_of_Visits_and_Events_Title:
        "History of Visits and Events: Farmers",
      Define_Field_Agent: "Define Field Agent",
      Administrators: "Administrators",
      Overview_Users: "Overview - Users",
      Overview_of_field_agents: "Overview of field agents",
      Overview_of_program_areas: "Overview of program areas",
      FarmersView: "Farmers' View",
      Phone: "Phone",
      Phone_Is_Required: "Phone is required",
      Permissions: "Permissions",
      Organization: "Organization",
      Name_is_Required: "Name is required",
      Name: "Name",
      User_Profile: "User Profile",
      Change: "Change",
      User_Profile_Pass_Change: "User / Password Change",
      Dashboard: "Dashboard",
      Mapa_Program: "Program Map",
      Areas_Program: "Program Areas",
      Farmers: "Farmers",
      Agents: "Field Agents",
      Suppliers: "Suppliers",
      Administration_and_users: "Administration and Users",
      Calendar: "Calendar",
      Action_Plan: "Action Plan",
      Checklists: "Checklists",
      Messages: "Messages",
      Notification_Center: "Notification Center",
      ChangePass: "Change Password",
      MyPerfil: "My Profile",
      Exit: "Exit",
      Name_APP: "Agroforestry and Restoration Accelerator",
      SubTitle_Recover_Pass_2:
        "You will receive a verification code to create your new password",
      RecoverPass: "Recover Password",
      SubTitle_Recover_Pass:
        "Please provide your email to recover your password",
      SubTitle_First_Access:
        "We have sent you an access code. Check your email and enter the code to complete your registration.",
      Numeric_Code_is_required: "The code is required",
      Numeric_Code_Only_Digits: "The code must be only digits",
      Confirm_Pass_is_required: "Password confirmation is required",
      Pass_must_match: "Passwords do not match",
      Confirm_Pass: "Confirm Password",
      Numeric_Code: "Code",
      Registration_Confirmation: "Registration Confirmation",
      Send: "Send",
      Sending: "Sending...",
      Welcome_to_the_Admin_Portal: "Welcome to the Admin Portal",
      English: "English",
      Portuguese: "Portuguese",
      Login: "Login",
      Pass_Should_Contain_At_Least_1_Special_Character:
        "Password should contain at least 1 special character",
      Pass_Least8: "Password must have at least 8 characters",
      Pass_Should_Contain_At_Least_1_Uppercase:
        "Password should contain at least 1 uppercase letter",
      Pass_Should_Contain_At_Least_1_Lowercase:
        "Password should contain at least 1 lowercase letter",
      Pass_Should_Contain_At_Least_1_Number:
        "Password should contain at least 1 number",
      Pass_is_required: "Password is required",
      Invalid_Email: "Invalid Email",
      Email_is_required: "Email is required",
      Email: "Email",
      Current_Pass: "Current Password",
      Pass: "Password",
      Recover_Pass: "Recover Password",
      To_Enter: "To Enter",
      Entering: "Entering...",
      FirstAccess: "First Access? Click here",
      TitleFilterMap: "Plotting Options",
      OptionFilterAreaMap1: "Farm Area",
      OptionFilterAreaMap2: "Agroforestry Area",
      OptionFilterAreaMap3: "Ecological Area",
      OptionFilterTItleStatusAreaMap: "Area Status",
      OptionFilterStatusAreaMap1: "Pending",
      OptionFilterStatusAreaMap2: "Approved",
      OptionFilterStatusAreaMap3: "Signed",
      OptionFilterStatusAreaMap4: "Rejected",
      LengentTitle: "Legend",
      LengentAreaMapTitle: "Farm Area",
      LengentAreaMapTitle1: "Agroforestry Area",
      LengentAreaMapTitle2: "Ecological Area",
      LengentAreaMapTitle3: "CAR Areas",
      LengentAreaMapTitle4: "Preservation Area",
      LengentAreaMapTitle5: "Indigenous Area",
      FARM_BOUNDARIES: "Farm Area",
      AGROFORESTRY: "Agroforestry",
      REForestation: "Reforestation",
      ACTION_PLAN_ITEMS: "Action Plan Items",
      CACAU_CONTRACT: "Cacao Sales Contract",
      upload_contract: "Select the file",
      request_type_call: "Call",
      request_type_scheduling: "Scheduling",
      request_type_visit: "Visit",
      TOTAL: "Total",
      pending_approval_action_plan: "Pending Approval",
      new_proposal_scheduling: "Pending Reschedule",
      user_legacy: "Legacy User",
      user_history: "Historical",
      calls: "Calls",
      events: "Events",
      received_the_call: "Received",
      didnt_received_the_call: "Expired",
      refused: "Refused",
      expired: "Expired",
      accepted: "Accepted",
      new_proposal: "Pending Reschedule",
      created: "Created",
      deleted: "Deleted",
      verified: "Verified",
      end_list: "No more items",
      pending_activities: "Pending Activities",
      farmer_assistance: "Farmer Assistance",
      download_contract: "Download Contract",
      contract_not_found: "Contract Not Found",
      next_events: "Upcoming Events",
      years: "years",
      request_type: "Type",
      areas_overlap: "Area Overlap",
      overlap: "Overlap",
      evaluate_area: "Evaluate Area",
      waiting_send: "Waiting to Send",
      approved: "Approved",
      rejected: "Rejected",
      status: "Status",
      description: "Description",
      btn_action: "Confirm Evaluation",
      btn_cancel: "Cancel",
      btn_save: "Save",
      message_error: "Both fields need to be filled",
      Edition: "Edition",
      admin_edit_page_title: "Edit Program Administrator",
      user_permission: "User Permissions",
      required_field: "Required Field",
      cta_button: "Save",
      invalid_field_name: "'Name' is required",
      name: "Name",
      invalid_field_email: "'Email' is required",
      email: "Email",
      invalid_field_phone: "'Phone' is required",
      phone: "Phone",
      minimum_of_8_characters: "Minimum of 8 characters",
      maximum_of_11_characters: "Maximum of 11 characters",
      field_agent: "Field Agent",
      agent_edit_page_title: "Edit Field Agent",
      agent_areas: "Field Agent Operation Areas",
      title_graphic_area: "Graphics Area",
      file_message_type: "File type not accepted.",
      message_import_contract: "Contract Imported successfully!",
      message_1_error_import_contract:
        "Error sending program contract, try again!",
      message_2_error_import_contract: "Error loading contract data!",
      possession_file: "Possession file",
      land_title_file: "Land title file",
      beneficiary_registration_file: "Beneficiary Registration",
      contract: "Contract",
      title_contract_signed: "Signed Contract",
      label_send_contract: "Upload Contract",
      title_document: "Documents attached",
      message_send_document: "Document sent successfully!",
      label_input_type_document: "Select a document type",
      label_input_file_document: "Select file",
      error_message_upload_document:
        "Error sending document, please try again!",
      label_add_document: "Add document",
      label_extra_document: "Additional Documents",
      message_question_delete_document:
        "Are you sure you want to delete this document?",
      title_modal_delete_document: "Attention",
      yes: "Yes",
      not: "Not",
      delete_document_feedback: "Document deleted successfully!",
      error_delete_document_feedback:
        "Error deleting document, please try again!",
      error_open_document: "Error opening file, try again!",
      SINGLE: "Single",
      MARRIED: "Married",
      DIVORCED: "Divorced",
      WIDOWED: "Widowed",
    },
  },
  pt: {
    translation: {
      file_message_type: "Tipo de arquivo não aceito.",
      DateNotAvailable: "Data não disponível",
      Credit: "Creditar",
      Deduct: "Deduzir",
      Restrict_farmer_carbon_credits:
        "Restringir Créditos de Carbono do agricultor",
      Manage_credit: "Gerenciar crédito",
      Balance: "Balanço",
      Emitted: "Emitido",
      Carbon_credit: "Crédito de Carbono",
      Exchanged: "Trocado",
      Historic: "Histórico",
      Category: "Categoria",
      Group_field_visit: "Visita de campo em grupo",
      Individual_field_visit: "Visita de campo individual",
      Attended_training: "Treinamento Frequentados",
      New: "Nova",
      Assistance: "Assistência",
      No_overlap_with_indigenous_lands:
        "Sem sobreposição com terras indígenas e quilombolas",
      No_deforestation_in_the_last_5_years:
        "Sem desmatamento nos últimos 5 anos",
      No_slavery: "Sem escravidão",
      No_overlap_with_preservation_areas:
        "Sem sobreposição com áreas de preservação",
      No_child_labor: "Sem trabalho infantil",
      Status_of_eligibility_criteria: "Status dos Critérios de Elegibilidade",
      Additional_information: "Informação Adicional",
      Main_field_agent: "Agente de campo principal",
      Size: "Tamanho",
      Code: "Código",
      Type_area: "Tipo de área",
      Type_cultivation: "Tipo de Cultivo",
      label_agent: "Agente de campo responsável",
      label_type_cultivation: "Tipo de cultivo",
      Name_area: "Identificador",
      Property_areas: "Áreas da Propriedade",
      ENROLLED: "Inscrito",
      see_on_map: "Ver no Mapa",
      Implemented_farmers: "Agricultores implementados",
      Enrolled_farmers: "Agricultores inscritos",
      OTHER: "Outros",
      Restauration_area: "Área de restauração",
      Agroforestry_area: "Área agroflorestal",
      ER_implemented: "ER Implementado",
      ER_enrolled: "ER Inscrito",
      AFS__implemented: "AFS Implementado",
      AFS_enrolled: "AFS Inscrito",
      Farmers_enrolled_and_deployed_regional_overview:
        "Agricultores inscritos X Agricultores implementados - visão geral regional",
      ha_implemented: "ha implementado",
      Total_area_of_registered_farms: "Área total das propriedades cadastradas",
      Participating: "Participando",
      See_it_all: "Ver Tudo",
      Monitoring_of_enrollment_steps: "Monitoramento das Etapas de Inscrição",
      title_information_personal: "INFORMAÇÕES PESSOAIS",
      label_name: "Nome",
      label_email: "E-mail",
      label_phone: "Celular",
      label_birth_date: "Data de Nascimento",
      label_cpf: "CPF",
      label_marital_status: "Estado Civil",
      label_level_of_education: "Nível de Educação",
      label_number_of_working_age_adults:
        "Número de adultos em idade de trabalho",
      label_number_of_children: "Número de crianças",

      title_information_property: "INFORMAÇÕES DA PROPRIEDADE",
      label_name_farm: "Nome",
      label_total_area: "Área Total da Propriedade",
      label_area_to_be_enrolled_afs: "Área a ser Inscrita Agrofloresta",
      label_area_to_be_enrolled_er: "Área a ser Inscrita Restauração Ecológica",
      label_organization_name: "Organização",
      label_city: "Cidade",
      label_state: "Estado",
      label_main_field_agent: "Agente de Campo Principal",
      label_has_beneficiary_registration: "RB - Registro de Beneficiário",
      label_has_land_title: "Título da Propriedade",
      label_has_possession: "Posse",
      label_multiple_owners: "Múltiplos Proprietários",

      title_information_production: "INFORMAÇÕES DE PRODUÇÃO",
      label_primary_farm_activity: "Atividade Agrícola Primária",
      label_secondary_farm_activity: "Atividade Agrícola Secundária",
      label_agriculture_area: "Área de Agricultura",
      label_forest_area: "Área de Floresta",
      label_pasture_area: "Área de Pasto",
      label_implementing_agroforestry: "Implementando Agrofloresta",
      label_cacao_plantation: "Plantação de cacau",
      user_history: "Histórico",
      label_step_1: "Etapa 1",
      label_step_1_subtitle: "Solicitar inscrição",
      label_step_2: "Etapa 2",
      label_step_2_subtitle: "Aprovação do programa",
      label_step_3: "Etapa 3",
      label_step_3_subtitle: "Agendar visita de um agente de campo",
      label_step_4: "Etapa 4",
      label_step_4_subtitle: "Áreas do programa: Mínimo de 1ha aprovados",
      label_step_5: "Etapa 5",
      label_step_5_subtitle: "Assinar contrato",
      label_step_6: "Etapa 6",
      label_step_6_subtitle: "Inscrito no Programa",

      Overview_of_program_areas: "Visão geral das áreas do programa",
      rate_of_top_10_field_agents:
        "Inscrito x Implementado - Taxa dos 10 principais agentes de campo",
      Enrolled_vs_Deployed: "Inscritos vs Implementados",
      No_Records: "Sem registros",
      User_Profile: "Perfil do usuário",
      Actions_Plan: "Planos de ação",
      Next_Appointments: "Próximos compromissos",
      Edit_User: "Editar usuário",
      VALUE: "Valor",
      CREDIT_ADDED: "Créditos adicionado",
      CREDIT_DEDECTED: "Créditos deduzido",
      PACKAGE_PURCHASE: "Compra de pacote",
      PACKAGE_UPDATE: "Atualização de Pacote",
      CREATION_OF_VENDOR: "Criação do fornecedor",
      MANUAL_CREDITS: "Adição de créditos manuais",
      PURCHASE_FROM_INDEPENDENT_SERVICES: "Compra de serviço individual",
      Localization: "Localização",
      Property: "Propriedade",
      Rejected_Description:
        "Caso sua escolha seja rejeitar, favor inserir uma descrição.",
      Status_Is_Required: "Status é obrigatório",
      Supplier: "Fornecedor",
      Reject: "Rejeitar",
      Accepted: "Aceitar",
      Offers: "Ofertas",
      Destinations: "Destinatários",
      Total_Sent: "Total Enviado:",
      UserId_Is_Required: "userId é obrigatório",
      Edit_Employee_Data: "Editar dados do funcionário",
      Comments: "Comentários",
      Ratings: "Avaliações",
      Save_Updates: "Salvar Atualizações",
      SalesmanId_Is_Required: "SalesmanId é obrigatório",
      Edit_suppliers: "Edite o responsável",
      Text_important_new_suppliers:
        "Importante! Ao adicionar um novo fornecedor, ele poderá incluir novos serviços",
      Text_edit_suppliers:
        "Você pode mudar a pessoa responsável para este Fornecedor.",
      EVENT_GROUP: "Evento em grupo",
      Salesman_Is_Required: "O funcionário é obrigatório",
      Salesmans: "Funcionários",
      has_Full_Control: "Controle total do fornecedor",
      Employee_phone: "Telefone do funcionário",
      Employee_name: "Nome do funcionário",
      Employee_email: "Email do funcionário",
      Invite_a_new_salesman:
        "Adicionar um novo funcionário para esse fornecedor",
      Text_new_salesman:
        "Por favor, preencha o formulário abaixo para adicionar um novo funcionário",
      In_Revison: "Em revisão",
      ACCEPTED: "Aceito",
      Services_Under_Review: "Serviços em revisão",
      Service: "Serviço",
      Cost: "Custo",
      Save_service: "Salvar serviço",
      Text_new_service:
        "Por favor, preencha o formulário abaixo para definir um novo serviço.",
      Information_About_The_Service: "Informações sobre o serviço",
      Cost_is_Required: "Custo é obrigatório",
      Add_new_Service: "Adicionar novo serviço",
      Services: "Serviços",
      To_Manage: "Gerenciar",
      Add_New_Salesman: "Adicionar novo funcionário",
      Edit_Supplier: "Editar fornecedor",
      Supplier_Registration: "Registro de fornecedores",
      Rating: "Avaliação",
      Available_Services: "Serviços disponíveis",
      cpf_cnpj: "Informe o CPF ou CNPJ",
      Responsible_phone: "Telefone do responsável",
      Responsible_email: "Email do responsável",
      Responsible_name: "Nome do responsável",
      Text_new_suppliers:
        "Por favor, preencha o formulário abaixo para convidar um novo fornecedor",
      Invite_a_new_suppliers: "Convide um novo fornecedor",
      Responsible_phone_Is_Required: "Telefone do responsável é obrigatório",
      Responsible_email_Is_Required: "Email do responsável é obrigatório",
      Responsible_name_is_Required: "Nome do responsável é obrigatório",
      Add_new_Suppliers: "Adicionar novo Fornecedor",
      RESPONSIBLE: "Responsável",
      Select_all: "Selecionar todos",
      Origin_is_required: "Origem é obrigatória",
      Select_type_notification: "Selecione o tipo de notificação",
      EVENT: "Evento",
      SCHEDULE_REQUEST: "Solicitação de agendamento",
      CALL: "Ligação",
      CHAT: "Chat",
      OTHERS: "Outros",
      Write_Your_Notification: "Escreva sua Notificação",
      Send_by_sms: "Enviar por SMS",
      Send_by_app: "Enviar pelo App",
      GENERATING: "Gerando",
      SENT: "Enviado",
      GENERATED: "Gerado",
      GeneratedIn: "Gerado em",
      Realized_Quantum: "Quantidade realizado",
      LastResponse: "Última Resposta",
      Type_report_is_required: "O tipo de relatório é obrigatório",
      To_generate: "Gerar",
      New_report: "Novo relatório",
      Type_Report: "Tipo de relatório",
      INCOMPLETE_EXTRA_INFORMATION_CRITERIA:
        "Critérios de informações extras incompletas",
      SIGNATURE_OF_CONTRACT: "Assinatura do contrato",
      LAST_30_DAYS: "Últimos 30 dias",
      LAST_6_MONTHS: "Últimos 6 meses",
      LAST_YEAR: "Último Ano",
      Reports: "Relatórios",
      Creating: "Criando",
      Start: "Iniciar",
      Message_is_Required: "Mensagem é obrigatório",
      Title_is_Required: "Titulo é obrigatório",
      New_chat: "Novo Chat",
      End_Chat: "Finalizar Chat",
      Uploading: "Enviando",
      Write_your_message_here: "Escreva sua mensagem aqui",
      Just_mine: "Apenas as minhas",
      It_will_only_be_read_when_all_of_the_batch_are_read:
        "Só estará lida quando todas do lote estiverem lidas",
      NotRead: "Não lido",
      Read: "Lido",
      Sent_by_the_system: "Enviado via sistema",
      Total_Sent: "Total Enviado",
      Author: "Autor",
      Message: "Mensagem",
      New_Message: "Nova mensagem",
      Notifications: "Notificações",
      Program_Area_title_page: "Mapa do Programa",
      Subscribers_to_the_Event: "Inscritos no evento",
      City: "Cidade",
      State: "Estado",
      Event_Data: "Dados do Evento",
      Close: "Fechar",
      Participated: "Participou",
      No: "Não",
      Responsible: "Responsável",
      Choose_an_event: "Escolha um evento",
      Check_presence_of_Farmers: "Verificar presença dos Agricultores(as)",
      Verify: "Confirmar participação em eventos",
      Select_Cities: "Selecione uma cidade",
      Localization_is_required: "Localização é obrigatória",
      Hour_is_Required: "A hora é obrigatória",
      Period_is_Required: "O período é obrigatório",
      Type_event_id_Is_Required: "Id do tipo de evento é obrigatório",
      Description_min_3: "Descrição deve conter no minimo 3 caracteres",
      Description_is_Required: "Descrição é obrigatória",
      Date_is_required: "Data é obrigatória",
      Address_min_3: "Endereço deve conter no minimo 3 caracters",
      Address_is_Required: "Endereço é obrigatório",
      State_is_Required: "Estado é obrigatório",
      City_is_Required: "Cidade é obrigatória",
      Period_or_hour_is_Required: "A hora ou o periodo deve ser preenchidos",
      UploadImage: "Upload da Imagem",
      RemoveImage: "Remover imagem",
      Hour: "Horário",
      Period: "Período",
      MORNING: "Manhã",
      AFTERNOON: "Tarde",
      Address: "Endereço",
      Cities: "Cidades",
      States: "Estados",
      INPERSON: "Presencial",
      Description: "Descrição",
      Title: "Titulo",
      Type_event: "Tipo de Evento",
      Verify_Event: "Verificar Evento",
      Add_event: "Adicionar Evento",
      Day_Events: "Eventos do dia",
      TRAINING: "Treinamento",
      WORKSHOP: "Workshop",
      FarmerResponse: "Resposta do Agricultor(a)",
      View_Item: "Visualizar Item",
      Approved_Per_Agent: "Aprovado pelo Agente de Campo",
      Rejected__Per_Agent: "Rejeitado pelo agente de Campo",
      REJECTED: "Rejeitado",
      RESOLVED: "Resolvido",
      REVIEW: "Em revisão",
      Completed_checklist: "Checklist Realizado",
      Created_in: "Criado em",
      All: "Todos",
      Total_Itens: "Total de itens",
      Overview_Action_Plans: "Visão dos Planos de Ação",
      Pending_Approval: "Pendente de Aprovação",
      ActionsPlans: "Planos de Ação",
      AgentField: "Agente de Campo",
      FarmerTitle: "Eventos",
      Required: "Obrigatório",
      Not_Required: "Não obrigatório",
      View_Document: "Visualizar documento",
      Not_Response: "Não respondido",
      Validate: "Validado",
      Total_Questions: "Questões Totais",
      Agent: "Agente de Campo",
      agent: "Agente de Campo",
      PerformedIn: "Realizado em",
      PENDING: "Pendente",
      ANSWERED: "Respondida",
      EXPIRED: "Expirado",
      COMPLETED: "Completo",
      NEW: "NEW",
      APPROVED: "Aprovado",
      SIGNED: "Assinado",
      Date: "Data",
      Finalized: "Finalizados",
      In_Progress: "Em progresso",
      Performed: "Realizados",
      Overview_Checklist: "Visão Geral dos Checklists",
      Graphic_4: "Total de Áreas Inscritas Vs Implementadas por Ha",
      Graphic_3: "Inscrito vs Áreas Implementadas",
      Implemented: "Implementada",
      Enrolled: "Inscrita",
      Graphic_2: "Áreas implementadas % por Quantidade de Visitas",
      Area_implemented: "Área implementada",
      Number_visits: "Número de visitas",
      Graphic_1: "Agricultores(as) vs Agentes de Campo - Região",
      Agents_per_regions: "Agentes por Região",
      Registered_farms: "Propriedades Inscritas",
      Active: "Ativo",
      List_available_field_agents: "Listagem de agentes de campo disponveis",
      Reset_field_agent: "Redefinir agente de campo",
      Identifier: "Identifier",
      Main_Field_Agent_is_Required: "Agente de Campo Principal é Obrigatório",
      At_least_one_secondary_field_agent_is_mandatory:
        "Ao menos um agente de campo secundário é obrigatória",
      Main_Field_Agent: "Agente de Campo Principal",
      Secondary_Field_Agents: "Agentes de Campo Secundários",
      ENROLLED_TO_THE_PROGRAM: "Inscrito no programa",
      FLAGGED_FOR_REVIEW: "Sinalizado para revisão",
      APPLICATION_DENIED: "Aplicação Rejeitada",
      IN_PROGRESS: "Em progresso",
      user_legacy: "Usuário Legado",
      Uninformed: "Não informado",
      Area: "Área",
      Farm_details: "Detalhes da Propriedade",
      Data: "Dados",
      List: "Listar",
      Location: "Localização",
      Farms: "Propriedades",
      Farm: "Propriedade",
      Farmer: "Agricultor(a)",
      farmer: "Agricultor(a)",
      admin: "Administrador",
      Define_field_agents: "Definir Agentes de Campo",
      Choose_regions: "Escolha as regiões",
      Regions_is_required: "É necessário ao menos uma região",
      Invite_a_new_admin: "Convide um novo administrador",
      Invite_a_new_agent: "Convide um novo agente de campo",
      Choose_permissions: "Escolha as permissões",
      Permissions_is_Required: "É necessário ao menos uma permissão",
      OrganizationId_Is_Required: "A organização é obrigatória",
      Organizations: "Organizações",
      Text_new_admin:
        "Por favor, preencha o formulário abaixo para convidar um novo administrador",
      Text_new_agent:
        "Por favor, preencha o formulário abaixo para convidar um novo agente de campo",
      Creation: "Criação",
      Add_new_user: "Adicionar novo usuário",
      Resend_first_access_code: "Reenviar código de primeiro acesso",
      Added_in: "Adicionado em",
      Never_accessed: "Sem acessos",
      Last_access_date: "Último Acesso",
      Regions: "Regiões",
      Search: "Pesquisar",
      Actions: "Ações",
      View: "Visualizar",
      Delete: "Deletar",
      Edit: "Editar",
      title_graphic_area: "Área de Gráficos",
      Gotoback: "Voltar",
      Overview: "Visão Geral",
      Edit_Allocation: "Editar Alocação",
      List_of_Available_Agents: "Listagem de Agentes Disponiveis",
      History_of_Visits_and_Events: "Histórico de Visitas e Eventos",
      History_of_Visits_and_Events_Title:
        "Histórico de Visita e Eventos: Agricultores(as)",
      Define_Field_Agent: "Definir Agente de Campo",
      Administrators: "Administradores",
      Overview_Users: "Visão Geral - Usuários",
      Overview_of_field_agents: "Visão geral dos agentes de campo",
      Overview_of_program_areas: "Visão geral das áreas do programa",
      FarmersView: "Visão dos Agricultores(a)",
      Phone: "Telefone",
      Phone_Is_Required: "Telefone é Obrigatório",
      Permissions: "Permissões",
      Organization: "Organização",
      Name_is_Required: "O nome é obrigatório",
      Name: "Nome",
      User_Profile: "Perfil do Usuário",
      Change: "Alterar",
      User_Profile_Pass_Change: "Usuário / Alteração de Senha",
      Dashboard: "Dashboard",
      Mapa_Program: "Mapa do Programa",
      Areas_Program: "Áreas do Programa",
      Farmers: "Agricultor(a)",
      Agents: "Agentes de Campo",
      Suppliers: "Fornecedores",
      Administration_and_users: "Administração de Usuários",
      Calendar: "Calendário",
      Action_Plan: "Plano de Ação",
      Checklists: "Checklists",
      Messages: "Mensagens",
      Notification_Center: "Centro de Notificações",
      ChangePass: "Alteração de senha",
      MyPerfil: "Meu perfil",
      Exit: "Sair",
      Name_APP: "Acelerador de Agroflorestas e Restauração",
      SubTitle_Recover_Pass_2:
        "Você receberá um código de verificação para criar sua nova senha",
      RecoverPass: "Recuperar Senha",
      SubTitle_Recover_Pass:
        "Por favor, forneça seu email para recuperar sua senha",
      SubTitle_First_Access:
        "Te enviamos um código de acesso. Verifique o seu email e insira o código para completar seu cadastro.",
      Numeric_Code_is_required: "O código é obrigatório",
      Numeric_Code_Only_Digits: "O código deve ser apenas números",
      Confirm_Pass_is_required: "A confirmação de senha é obrigatória",
      Pass_must_match: "As senha não conferem",
      Confirm_Pass: "Confirmar Senha",
      Numeric_Code: "Código",
      Registration_Confirmation: "Confirmação de Cadastro",
      Send: "Enviar",
      Sending: "Enviando ...",
      Welcome_to_the_Admin_Portal: "Bem vindo ao Portal do Administrador",
      English: "Inglês",
      Portuguese: "Português",
      Login: "Acesso",
      Pass_Should_Contain_At_Least_1_Special_Character:
        "A senha deve conter ao menos um caracter especial",
      Pass_Least8: "A senha deve ter no mínimo 8 caracteres",
      Pass_Should_Contain_At_Least_1_Uppercase:
        "A senha deve conter pelo menos uma letra maiúscula",
      Pass_Should_Contain_At_Least_1_Lowercase:
        "A senha deve conter pelo menos uma letra minúscula",
      Pass_Should_Contain_At_Least_1_Number:
        "A senha deve conter pelo menos um número",
      Pass_is_required: "A senha é obrigatória",
      Invalid_Email: "Email inválido",
      Email_is_required: "Email é obrigatório",
      Email: "Email",
      Current_Pass: "Senha Atual",
      Pass: "Senha",
      Recover_Pass: "Recuperar Senha",
      To_Enter: "Entrar",
      Entering: "Entrando ...",
      FirstAccess: "Primeiro Acesso? Clique aqui",
      TitleFilterMap: "Tipos de Área",
      OptionFilterAreaMap1: "Propriedade",
      OptionFilterAreaMap2: "Agrofloresta",
      OptionFilterAreaMap3: "Restauração Ecológica",
      OptionFilterTItleStatusAreaMap: "Status das Áreas",
      OptionFilterStatusAreaMap1: "Área Pendente",
      OptionFilterStatusAreaMap2: "Aprovado pelo Agente de Campo",
      OptionFilterStatusAreaMap3: "Contrato Assinado",
      OptionFilterStatusAreaMap4: "Área Recusada",
      LengentTitle: "Legendas",
      LengentAreaMapTitle: "Propriedade",
      LengentAreaMapTitle1: "Agrofloresta",
      LengentAreaMapTitle2: "Restauração Ecológica",
      LengentAreaMapTitle3: "CAR",
      LengentAreaMapTitle4: " Unidades de Conservação",
      LengentAreaMapTitle5: "Terras Indígenas",
      FARM_BOUNDARIES: "Área da Propriedade",
      AGROFORESTRY: "Agrofloresta",
      REFORESTATION: "Reflorestamento",
      ACTION_PLAN_ITEMS: "Itens Plano de Ação",
      CACAU_CONTRACT: "Contrato de Venda de Cacau",
      upload_contract: "Selecione o arquivo",
      request_type_call: "Ligação",
      request_type_scheduling: "Agendamento",
      request_type_visit: "Visita",
      TOTAL: "Total",
      pending_approval_action_plan: "Aprovação Pendente",
      new_proposal_scheduling: "Reagendamento Pendente",
      calls: "Ligações",
      events: "Eventos",
      received_the_call: "Realizado",
      didnt_received_the_call: "Expirado",
      refused: "Recusado",
      expired: "Expirado",
      accepted: "Aceito",
      new_proposal: "Reagendamento Pendente",
      created: "Criado",
      deleted: "Deletado",
      verified: "Verificado",
      end_list: "Não há mais itens",
      pending_activities: "Atividades Pendentes",
      farmer_assistance: "Assistência ao Agricultor(a)",
      download_contract: "Baixar Contrato",
      contract_not_found: "Não Existe Contrato",
      next_events: "Próximos compromissos",
      years: "anos",
      request_type: "Tipo",
      areas_overlap: "Sobreposição de Áreas",
      overlap: "Sobreposição",
      evaluate_area: "Avaliar Área",
      waiting_send: "Aguardando envio",
      approved: "Aprovada",
      rejected: "Recusada",
      status: "Status",
      description: "Descrição",
      btn_action: "Confirmar Avaliação",
      btn_cancel: "Cancelar",
      btn_save: "Salvar",
      message_error: "Os 2 campos precisam ser preenchidos",
      Edition: "Edição",
      admin_edit_page_title: "Editar Administrador do Programa",
      user_permission: "Permissões do Usuário",
      required_field: "Campo obrigatório",
      cta_button: "Salvar",
      invalid_field_name: "'Nome' é obrigatório",
      name: "Nome",
      invalid_field_email: "'E-mail' é obrigatório",
      email: "E-mail",
      invalid_field_phone: "'Telefone' é obrigatório",
      phone: "Telefone",
      minimum_of_8_characters: "Mínimo de 8 caracteres",
      maximum_of_11_characters: "Máximo de 11 caracteres",
      field_agent: "Agente",
      agent_edit_page_title: "Editar Agente de Campo",
      agent_areas: "Áreas de operação do Agente",
      message_import_contract: "Contrato Importado com sucesso!",
      message_1_error_import_contract:
        "Erro ao enviar o contrato do programa, tente novamente!",
      message_2_error_import_contract: "Erro ao carregar dados do contrato!!!",
      possession_file: "Arquivo de posse",
      land_title_file: "Arquivo de título de terra",
      beneficiary_registration_file: "Registro de Beneficiário",
      contract: "Contrato",
      title_contract_signed: "Contrato Assinado",
      label_send_contract: "Carregar contrato",
      title_document: "Documentos Anexados",
      message_send_document: "Documento enviado com sucesso!",
      label_input_type_document: "Selecione um tipo de documento",
      label_input_file_document: "Selecione o arquivo",
      error_message_upload_document:
        "Erro ao enviar documento, tente novamente!",
      label_add_document: "Adicionar documento",
      label_extra_document: "Documentos Adicionais",
      message_question_delete_document:
        "Tem certeza que deseja deletar esse documento ?",
      title_modal_delete_document: "Atenção",
      yes: "Sim",
      not: "Não",
      delete_document_feedback: "Documento deletado com sucesso!",
      error_delete_document_feedback:
        "Erro ao deletar o documento, tente novamente!",
      error_open_documento: "Erro ao abrir o arquivo, tente novamente!",
      SINGLE: "Solteiro",
      MARRIED: "Casado",
      DIVORCED: "Divorciado",
      WIDOWED: "Viúvo",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
