import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Row,
  Select,
} from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import BCFlagSelector from "../../components/BCFlagSelector";
import { phoneMaskBR } from "../../utils/mask";
import { createAdmin, getOrganizations, getPermissions } from "./service";
import { useLanguage } from "../../contexts/languageContext";
const { Option } = Select;
const { Meta } = Card;

function AdministrationCreateAdmin() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const { language } = useLanguage();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
    phone: Yup.string().required(t("Phone_Is_Required")),
    phoneCode: Yup.string().required(t("Phone_Is_Required")),
    name: Yup.string().required(t("Name_is_Required")),
    organizationId: Yup.string().required(t("OrganizationId_Is_Required")),
    permissions: Yup.array()
      .required(t("Permissions_is_Required"))
      .min(1, t("Permissions_is_Required")),
  });

  const findOrganizations = useCallback(async () => {
    const result = await getOrganizations();
    setOrganizations(result);
  }, []);

  const findPermissions = useCallback(async () => {
    const result = await getPermissions();
    setPermissions(result.result);
  }, []);

  useEffect(() => {
    findOrganizations();
    findPermissions();
  }, [findOrganizations, findPermissions]);

  async function createUser(values) {
    await createAdmin(
      values.name,
      values.email,
      `${values.phoneCode} ${values.phone}`,
      values.organizationId,
      values.permissions
    );
    navigate("/administration-users/list-admins");
  }

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        phone: "",
        organizationId: "",
        permissions: [],
        phoneCode: "+55",
      }}
      validationSchema={validationSchema}
      onSubmit={createUser}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "green", color: "white" }}
                icon={<UserOutlined />}
              />
            }
            title={t("Invite_a_new_admin")}
            description={t("Text_new_admin")}
          />
          <Row style={{ marginTop: "30px" }} gutter={16}>
            <Col xs={24} sm={6}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.name && errors.name ? errors.name : ""}
                        validateStatus={
                          touched.name && errors.name ? "error" : ""
                        }
                      >
                        <Input
                          name="name"
                          className="input"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.email && errors.email ? errors.email : ""}
                        validateStatus={
                          touched.email && errors.email ? "error" : ""
                        }
                      >
                        <Input
                          prefix={<MailOutlined />}
                          name="email"
                          className="input"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Email")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.phone && errors.phone ? errors.phone : ""}
                        validateStatus={
                          touched.phone && errors.phone ? "error" : ""
                        }
                      >
                        <Input
                          addonBefore={
                            <BCFlagSelector
                              selected={values.phoneCode}
                              onSelect={(value) =>
                                handleChange({
                                  target: { name: "phoneCode", value },
                                })
                              }
                            />
                          }
                          name="phone"
                          className="input"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Phone")}
                          onKeyUp={(event) => phoneMaskBR(event)}
                          prefix={<PhoneOutlined />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} md={24}>
                      <Form.Item
                        help={
                          touched.organizationId &&
                          errors.organizationId &&
                          errors.organizationId
                            ? errors.organizationId
                            : ""
                        }
                        validateStatus={
                          touched.organizationId && errors.organizationId
                            ? "error"
                            : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          name="organizationId"
                          value={values.organizationId}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "organizationId", value },
                            })
                          }
                          onBlur={handleBlur}
                          placeholder={t("Organizations")}
                        >
                          <Option value="" disabled>
                            {t("Organizations")}
                          </Option>
                          {organizations.map((x) => (
                            <Option value={x._id} key={x._id}>
                              {x.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={6}>
              <Form.Item
                label={t("Choose_permissions")}
                help={
                  touched.permissions && errors.permissions
                    ? errors.permissions
                    : ""
                }
                validateStatus={
                  touched.permissions && errors.permissions ? "error" : ""
                }
              >
                <Checkbox.Group
                  name="permissions"
                  value={values.permissions}
                  onChange={(checkedValues) =>
                    handleChange({
                      target: { name: "permissions", value: checkedValues },
                    })
                  }
                >
                  <List
                    dataSource={permissions}
                    renderItem={(permission) => (
                      <List.Item>
                        <Checkbox
                          value={permission._id}
                          style={{
                            "--checkbox-border-color":
                              values.permissions.includes(permission._id)
                                ? "green"
                                : "",
                          }}
                        >
                          {language  === 'en-us' ? permission.name : permission.name_pt}
                        </Checkbox>
                      </List.Item>
                    )}
                  />
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <BCButtonConfirm
              style={{ width: "100%", maxWidth: "200px", marginTop: "20px" }}
              buttonText={t("Send")}
            ></BCButtonConfirm>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default AdministrationCreateAdmin;
