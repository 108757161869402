import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import CallsEvents from "../../components/CallsEvents/CallsEvents";
import SelectFarmer from "../../components/SelectFarmer/SelectFarmer";
import Agents from "../../pages/Agents/Agents";
import AgentsDashboard from "../../pages/Agents/AgentsDashboard";
import AgentsDefineFieldAgents from "../../pages/Agents/AgentsDefineFieldAgents";
import AgentsEditDefineFieldAgents from "../../pages/Agents/AgentsEditDefineFieldAgentsList";
import AgentsList from "../../pages/Agents/AgentsList";
import AgentsListAvailable from "../../pages/Agents/AgentsListAvailable";
import AgentsRatingsView from "../../pages/Agents/AgentsRatingsView";
import AgentsView from "../../pages/Agents/AgentsView";
import AgentsViewActionsPlans from "../../pages/Agents/AgentsViewActionsPlans";
import AgentsViewChecklists from "../../pages/Agents/AgentsViewChecklists";
import BaseLayout from "../../pages/BaseLayout";
import Private from "../Private";

import { useNavigate } from "react-router-dom";
import AgentsViewFarmers from "../../pages/Agents/AgentsViewFarmers";
import AgentsViewEvents from "../../pages/Agents/AgentsViewEvents";

function AgentsRoutes() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Agentes")}`}>
              <Agents />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/overview"
        element={
          <Private>
            <BaseLayout
              gotoback="/agents"
              breadcrumb={`${t("Agentes")} / ${t("Overview")}`}
            >
              <AgentsDashboard />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/list-available-field-agents"
        element={
          <Private>
            <BaseLayout
              gotoback="/agents"
              breadcrumb={`${t("Agentes")} / ${t(
                "List_available_field_agents"
              )} `}
            >
              <AgentsListAvailable />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/history-visits-events/:farmerId"
        element={
          <Private>
            <BaseLayout
              gotoback="/agents"
              breadcrumb={`${t("Agentes")} / ${t(
                "History_of_Visits_and_Events"
              )} `}
            >
              <CallsEvents></CallsEvents>
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/history-visits-events"
        element={
          <Private>
            <BaseLayout
              gotoback="/agents"
              breadcrumb={`${t("Agentes")} / ${t(
                "History_of_Visits_and_Events"
              )} `}
            >
              <SelectFarmer
                title={t("History_of_Visits_and_Events_Title")}
                navigateToFunc={(farmerId) =>
                  navigate("/agents/history-visits-events/" + farmerId)
                }
              ></SelectFarmer>
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/define-list"
        element={
          <Private>
            <BaseLayout
              gotoback="/agents"
              breadcrumb={`${t("Agentes")} / ${t("List")} `}
            >
              <AgentsList />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/define-field-agents"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Agentes")} / ${t("Define_field_agents")}`}
              gotoback="/agents/define-list"
            >
              <AgentsDefineFieldAgents />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/define-edit-list"
        element={
          <Private>
            <BaseLayout
              gotoback="/agents"
              breadcrumb={`${t("Agentes")} / ${t("List")} `}
            >
              <AgentsList />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/edit-field-agents"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Agentes")} / ${t("Reset_field_agent")}`}
              gotoback="/agents/define-edit-list"
            >
              <AgentsEditDefineFieldAgents />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/view"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Agents")} / ${t("view")} `}
              gotoback="/agents"
            >
              <AgentsView />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/actions-plans-agent"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Agents")} / ${t("view")} / ${t("Actions")} `}
              gotoback="/agents"
            >
              <AgentsViewActionsPlans />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/actions-farmers-by-agents"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Agents")} /`} gotoback="/agents">
              <AgentsViewFarmers />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/actions-events-by-agents"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Agents")} /`} gotoback="/agents/overview">
              <AgentsViewEvents />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/checklists-agent"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Agents")} / ${t("view")} / ${t("checklist")} `}
              gotoback="/agents"
            >
              <AgentsViewChecklists />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/rate-agent"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Agents")} / ${t("Rate")} `}
              gotoback="/agents"
            >
              <AgentsRatingsView />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default AgentsRoutes;
